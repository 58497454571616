var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "商品明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container list"
  }, _vm._l(_vm.orderGoodsList, function (orderGoods) {
    return _c('goods', {
      key: orderGoods.id,
      attrs: {
        "goods": orderGoods
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }