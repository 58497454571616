<template>
  <div class="picking-goods-container">
    <div class="picking-goods-container__content">
      <div class="picking-goods-container__content-img">
        <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
      </div>
      <div class="picking-goods-container__content-text">
        <div class="picking-goods-container__content-name">{{ goods.name }}</div>
        <div class="picking-goods-container__size-storage">
          <span>规格：1 * {{ goods.size }}</span>
        </div>
        <div class="picking-goods-container__large-least">
          <span v-if="goods.good_volume > 0">完好的：{{ goods.good_volume }}{{ goods.least_unit }}</span>
          <span v-if="goods.good_volume > 0 && goods.bad_volume > 0" style="padding: 0 2px" />
          <span v-if="goods.bad_volume > 0">损坏的：{{ goods.bad_volume }}{{ goods.least_unit }}</span>
        </div>
        <div class="picking-goods-container__amount">
          金额：
          <span>￥{{ goods.amount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { unitsToVolume, volumeToUnits } from '@/utils'
export default {
  name: 'PickingGoods',
  props: {
    goods: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outLargeVolume: 0,
      outLeastVolume: 0
    }
  },
  watch: {
    goods: {
      handler: function() {
        this.calcOutVolume()
      },
      deep: true
    }
  },
  created() {
    this.calcOutVolume()
  },
  methods: {
    handlePick() {
      this.$emit('pick-goods', this.goods)
    },
    handleShortage() {
      this.$emit('goods-shortage', this.goods)
    },
    calcOutVolume() {
      const pickVolume = unitsToVolume(this.goods.pick_large_volume, this.goods.pick_least_volume, this.goods.size)
      const outVolume = this.goods.volume - pickVolume
      const units = volumeToUnits(outVolume, this.goods.size)
      this.outLargeVolume = units.largeVolume
      this.outLeastVolume = units.leastVolume
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/ellipsis";
  @import "src/styles/word-break";
  @import "src/styles/color";
  .picking-goods-container {
    position: relative;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
    font-size: 13px;
    &__content {
      display: flex;
      padding: 15px;
    }
    &__content-img {
      width: 85px;
      height: 85px;
    }
    &__large-least {
      color: #646566;
    }
    &__amount {
      color: $amountRedColor;
    }
    &__content-text {
      @extend %ellipsis;
      padding: 0 10px;
    }
    &__content-name {
      @extend %word-break;
      color: #1C1C1C;
      // margin-bottom: 5px;
    }
    &__size-storage {
      color: #646566;
      // margin-bottom: 5px;
    }
    &__pick-info {
      padding: 0 15px 15px 15px;
    }
    &__pick-button {
      text-align: right;
      .van-button {
        height: 30px;
        /*width: 85px;*/
      }
    }
    &__finished-logo {
      position: absolute;
      top: 0;
      right: 0;
      /*width: 80px;*/
      /*height: 80px;*/
      /*z-index: 10;*/
    }
  }
</style>
