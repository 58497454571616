var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "picking-goods-container"
  }, [_c('div', {
    staticClass: "picking-goods-container__content"
  }, [_c('div', {
    staticClass: "picking-goods-container__content-img"
  }, [_c('van-image', {
    attrs: {
      "width": "2.26666rem",
      "height": "2.26666rem",
      "src": _vm.goods.image
    }
  })], 1), _c('div', {
    staticClass: "picking-goods-container__content-text"
  }, [_c('div', {
    staticClass: "picking-goods-container__content-name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "picking-goods-container__size-storage"
  }, [_c('span', [_vm._v("规格：1 * " + _vm._s(_vm.goods.size))])]), _c('div', {
    staticClass: "picking-goods-container__large-least"
  }, [_vm.goods.good_volume > 0 ? _c('span', [_vm._v("完好的：" + _vm._s(_vm.goods.good_volume) + _vm._s(_vm.goods.least_unit))]) : _vm._e(), _vm.goods.good_volume > 0 && _vm.goods.bad_volume > 0 ? _c('span', {
    staticStyle: {
      "padding": "0 2px"
    }
  }) : _vm._e(), _vm.goods.bad_volume > 0 ? _c('span', [_vm._v("损坏的：" + _vm._s(_vm.goods.bad_volume) + _vm._s(_vm.goods.least_unit))]) : _vm._e()]), _c('div', {
    staticClass: "picking-goods-container__amount"
  }, [_vm._v(" 金额： "), _c('span', [_vm._v("￥" + _vm._s(_vm.goods.amount))])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }